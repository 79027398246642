<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="miscellaneousAdminCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="outboundNotifications">{{ $t('message.outboundNotifications') }}</v-tab>
                        <v-tab key="tmsTransfers">{{ $t('message.tmsTransfers') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="miscellaneousAdminCurrentTab">
                        <v-tab-item key="outbound-notifications-list">
                            <v-card flat>
                                <PendingOutboundNotifications/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="tms-transfers-list">
                            <v-card flat>
                                <PendingTmsTransfers/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";

const PendingOutboundNotifications = () => import("Components/Appic/PendingOutboundNotifications");
const PendingTmsTransfers = () => import("Components/Appic/PendingTmsTransfers");

export default {
    name: "MiscellaneousAdmin",
    components: {PendingTmsTransfers, PendingOutboundNotifications},
    data() {
        return {
            editDialog: false,
            loader: false,
            loading: {
                save: false,
                settings: false
            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            miscellaneousAdminCurrentTab: 'miscellaneousAdminCurrentTab'
        }),
        headers() {
            return {
                outboundNotifications: [],
                tmsTransfers: [],
            }
        }
    },
}
</script>

<style>
</style>